<template>

  <div>

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="roleFilter"
              :options="collateralConditions"
              class="w-100"
              :reduce="val => val.value"
            />
          </b-col>
          <!-- <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Type</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="roleFilter"
              :options="collateralStatus"
              class="w-100"
              :reduce="val => val.value"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Brand</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="roleFilter"
              :options="collateralStatus"
              class="w-100"
              :reduce="val => val.value"
            />
          </b-col> -->
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('create', 'collaterals')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-add
                variant="primary"
                class="btn-icon"
                @click="$router.push('collateral/create')"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="collaterals"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="tableOptions.sortBy"
        show-empty
        empty-text="No matching records found"
        table-class="text-nowrap"
        :sort-desc.sync="tableOptions.isSortDirDesc"
      >

        <template #cell(status)="data">
          <div style="width: 90px;">
            <b-badge
              v-if="collateralStatus.find(d => d.key === data.value)"
              :variant="`light-${collateralStatus.find(d => d.key === data.value).type}`"
              class="circle-rounded px-1 font-weight-normal mb-50"
            >
              {{ $t(data.value) }}
            </b-badge>
          </div>
        </template>

        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <div>
              <b-avatar
                rounded
                :src="data.item.productImages.length > 0 ? data.item.productImages[0].url : null"
                size="32"
                :text="data.item.productName"
                :to="{ name: 'collateral', params: { pid: data.item.pid } }"
              />
            </div>
            <div class="ml-1 text-left">
              <b-link
                :to="{ name: 'collateral', params: { pid: data.item.pid } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.productName }}
              </b-link>
              <div class="text-muted">
                {{ data.item.productBrand }}
              </div>
            </div>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { mapGetters } from 'vuex'
// import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tableOptions: {
        sortBy: 'createdAt',
        isSortDirDesc: true,
      },
      searchQuery: '',
      roleFilter: null,
      collateralStatus: [],
      collateralConditions: [],
      tableColumns: [
        {
          key: 'status',
          label: this.$t('status'),
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('collaterals'),
          sortable: true,
        },
        {
          key: 'productCondition',
          label: this.$t('condition'),
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'productYear',
          label: this.$t('product year'),
          sortable: true,
        },
        {
          key: 'productModel',
          label: this.$t('product model'),
          sortable: true,
        },
        {
          key: 'productType',
          label: this.$t('product type'),
          sortable: true,
        },
        {
          key: 'customer',
          label: this.$t('customer'),
        },
      ],
      refUserListTable: null,
      currentPage: 1,
      perPage: 25,
      totalUsers: 0,
    }
  },
  computed: {
    ...mapGetters({
      collaterals: 'getCollaterals',
      user: 'getUser',
    }),
    dataMeta() {
      const localItemsCount = this.refUserListTable ? this.refUserListTable.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
  },
  mounted() {
    store.dispatch('fetchCollateralsList')
    this.collateralStatus = store.state.collaterals.collateralStatus
    store.state.collaterals.collateralConditions.forEach(stats => {
      this.collateralConditions.push({
        key: stats,
        title: this.$t(stats),
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
